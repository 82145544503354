@import '../../../../styles/_base.scss';

.title {
  @include headingH2Section;
}
.status {
  text-align: right;
}
.statusDescription {
  @include status;
}
.downloadLinkHolder {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
}
.btn {
  @include downloadBtn;
}
