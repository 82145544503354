@import '../../../../styles/_base.scss';

.card {
  @include tabContent;
}
.cvvInfo {
  display: block;
  p {
    @include generalP;
  }
  h4 {
    @include generalP;
    font-weight: 700;
  }
}
.cvvLink {
  @include showHideLink;
  margin-bottom: 1rem;
}
.cvvText {
  display: flex;
  gap: 1rem;
  img {
    width: auto;
    height: auto;
    margin: 0;
  }
}
.previousSelect {
  @include generalSelect;
}
.previousSubmit {
  @include btn;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.newSubmit {
  @include btn;
  width: auto;
  margin-top: 1rem;
}
.cardHeading {
  @include headingH3;
}
.previousCards {
  width: 100%;
}
.frames {
  width: 100%;
}
.iframe3DS {
  width: 100%;
  min-height: 500px;
  border: 0;
  padding: 0;
  margin: 0 0 2rem 0;
}

.cardFrame {
  background: #fff;
  border: 1px solid #00273b;
  border-radius: 4px;
  height: 50px;
  @media (min-width: 1110px) {
    width: 70%;
  }
  margin-bottom: 1rem;
}

.cardFrameUO {
  background: #fff;
  border: 1px solid #00273b;
  border-radius: 4px;
  height: 50px;
  min-width: 380px;
  margin-bottom: 1rem;
}
