@import '../../../styles/_base.scss';

.login {
  width: 100%;
}

.fieldset {
  @include fieldset;
}

.field {
  @include field;
}

.label {
  @include generalLabel;
}

.inputWrap {
  width: 100%;
  padding: 0 $form-icon-gutter 0 0;
  display: block;
  position: relative;

  &:global(.valid) {
    @include tick();
  }

  &:global(.error) {
    @include cross();
  }
}

.prefix {
  position: absolute;
  top: 0;
  left: 0;
  font-size: $font-size-medium;
  line-height: 1.4;
  padding: 1.5rem 1rem;
  border: solid 1px transparent;
}

.inputNumber {
  @include generalInput;
  padding-left: 55px;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.inputEmail {
  @include generalInput;
}

.customerAccountsSubtitle {
  @include headingH2;

  width: 100%;
  margin: 0 0 2rem 0;
  padding: 0 0 0.5rem 0;
  border-bottom: solid 2px $white;
}

.customerAccountsIntro {
  @include generalP;
}

.error {
  @include generalError;
}

.button {
  @include btn;
  width: calc(100% - $form-icon-gutter);
}

.errmsg {
  @include generalP;
  color: $error;
  padding: 1rem;
  border: solid 1px $error;
  width: 100%;
  padding-left: 35px;
  width: calc(100% - $form-icon-gutter);

  @include cross(10px, 17px, auto, auto);
}

.tooltipTitle {
  @include tooltipTitle;
}

.tooltipBody {
  @include tooltipBody;
}

.required {
  @include required;
}
