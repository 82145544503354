@import '../../../../styles/_base.scss';

.paypal {
  @include tabContent;
}
.paypalFrame {
  width: 100%;
  iframe {
    width: 100%;
    min-height: 0;
    border: 0;
    padding: 0;
    margin: 0;
  }
}
.payNow {
  @include linkBtn;
  width: auto;
}
