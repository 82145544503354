@import '../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.quote {
  @include tabContent;
}

.ticketsList {
  @include ticketsList;
}

.comboTicketsList {
  @include ticketsList;
  padding-bottom: 20px;
}

.btn {
  @include downloadBtn;
  margin-top: 10px;
}
