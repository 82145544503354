@import '../../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.ticketsList {
  @include ticketsList;
}

.downloadTickets {
  @include tabContent;
}
