@import '../../../styles/_base.scss';

.customerAccountsSubtitle {
  @include headingH2;

  width: 100%;
  margin: 0 0 2rem 0;
  padding: 0 0 0.5rem 0;
  border-bottom: solid 2px $white;
}

.customerAccountsIntro {
  @include generalP;
}

.list {
  @include generalList;
}

.telephone {
  @include generalP;
  font-size: $font-size-bigger;
  a {
    @include linkColor;
  }
}

.opening {
  @include generalP;
}

.linkBtn {
  margin-bottom: 2rem;
  a {
    @include linkBtn;
  }
}
