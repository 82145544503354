@import '../../../../styles/_base.scss';

.giftcard {
  @include tabContent;
}

.fieldset {
  @include fieldset;
}

.field {
  @include field;
}

.label {
  @include generalLabel;
}

.input {
  @include generalInput;
}

.verify {
  @include linkBtn;
  width: auto;
}

.required {
  @include required;
}

.newSubmit {
  @include btn;
  width: auto;
  margin-top: 1rem;
}
