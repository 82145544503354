@import "../../../../styles/_base.scss";

.required {
  @include required;
}

.error {
  @include generalError;
  font-size: 1.4rem;
}

.close {
  @include linkBtn;
  @include fromTablet {
    top: 30px;
  }
  width: auto;
  position: fixed;
  top: 65px;
  right: 30px;
  border-radius: 0px;
}

.title {
  @include headingH2Status;
}

h2, h3, h4 {
  @include headingH3;
  margin-top: 10px;
}

p {
  @include generalP;
}

input {
  margin: 2px 5px 0 0;
}

.leftText {
  text-align: left;
}
