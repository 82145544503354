@import '../../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.hotel {
  @include accommodation;
}

.image {
  @include accommodationImage;
}

.info {
  @include accommodationInfo;
}

.infoHeader {
  display: flex;
  justify-content: space-between;
}

.hotelName {
  @include headingH2;
}

.hotelSubtitle {
  @include generalP;
}

.bookingStatus {
  @include generalP;
  font-weight: bold;
  font-size: 150%;
  font-style: italic;
}

.supplierConfirmationNumber {
  font-weight: bold;
  font-style: italic;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  @include fromDesktop {
    margin-top: 2rem;
    flex-direction: row;
    gap: 2rem;
  }
}

.detailsChunk {
  @include backgroundChunk;
  flex-basis: 100%;
  flex-grow: 1;
  @include fromDesktop {
    flex-basis: 40%;
  }
}

.priceIncludes {
  flex-basis: 100%;
  flex-grow: 1;
  @include fromDesktop {
    flex-basis: 40%;
    text-align: right;
  }
}

// Lists

.checkInOut {
  list-style-type: none;
  margin: 0 0 -0.5rem 0;
  padding: 0;
}

.checkInOutItem {
  @include generalP;
  margin-bottom: 0.5rem;
  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
}

.includesHeading {
  @include generalP;
}

.includes {
  list-style-type: none;
  margin: 0 0 1rem 0;
  padding: 0;
  display: flex;
  gap: 2rem;
  @include fromDesktop {
    justify-content: flex-end;
  }
}

.includesItem {
  @include generalP;
  margin-bottom: 0;
  text-align: left;
  display: flex;
  gap: 0.5rem;
  svg {
    width: 30px;
    height: 30px;
    margin-top: -3px;
  }
  strong {
    display: block;
  }
  span {
    display: block;
  }
}

.eurostar {
  svg {
    width: 20px;
  }
}

.nights {
  svg {
    width: 30px;
  }
}

.daysParks {
  svg {
    width: 30px;
  }
}

.address {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.addressItem {
  @include generalP;
  margin-bottom: 0;
}
