@import "../../../../styles/_base.scss";

.googlepay {
  @include tabContent;
}

.iframe3DS {
  width: 100%;
  min-height: 500px;
  border: 0;
  padding: 0;
  margin: 0 0 2rem 0;
}

.googleButton {
  margin-top: 10px
}

.disabledButton {
  margin-top: 10px;
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}
