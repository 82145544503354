@import '../../../styles/_base.scss';

.travelDocuments {
  @include tabContent;
}

.status {
  text-align: right;
}

.statusDescription {
  @include status;
}

.btn {
  @include downloadBtn;
}
