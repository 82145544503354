@import '../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.downloadTickets {
  @include tabContent;
}

.ticketsList {
  @include ticketsList;
}

.comboTicketsList {
  @include ticketsList;
  padding-bottom: 20px;
}
