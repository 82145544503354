@import '../../styles/_base.scss';

.customerAccounts {
  @include pageContainer;
}

.customerAccountsTitle {
  width: 100%;
  margin-bottom: 2rem;
}

.customerAccountsChunks {
  @include infoChunks;
}

.customerAccountsNonATDChunks {
  @include infoChunks;
  @media (min-width: 1110px) {
    width: 50%;
  }
}

.customerAccountsChunk {
  @include infoChunk;
}

.loading {
  height: 60px;
}
