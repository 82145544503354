@import '../../../../styles/_base.scss';

.paymentChunk {
  flex-basis: 100%;
  flex-grow: 1;
  @include fromDesktop {
    flex-basis: 40%;
  }
}

.editLink {
  @include showHideLink;
}

.fieldset {
  @include fieldset;
}

.field {
  @include field;
  margin-bottom: 0;
}

.label {
  @include generalLabel;
}

.error {
  @include generalError;
}

.addressLabel {
  @include generalLabel;
}

.address {
  @include generalP;
  margin: 0 0 0.5rem 0;
}

.input {
  @include generalInput;
}

.inputFirst {
  margin-bottom: 1rem;
}

.select {
  @include generalSelect;
}

.update {
  @include linkBtn;
  width: auto;
}

.tabList {
  @include tabList;
  width: calc(100vw - 4rem - 2px);
  @include fromTablet {
    width: calc(100vw - 5rem - 2px);
  }
  @include fromDesktop {
    width: 100%;
  }
}
.tab {
  @include tab;
}

.tabNoPadding {

  @include tab;
  padding: 0rem 1rem;
  width: 105px;
  @include fromDesktop {
      padding: 0rem 2rem;
    }
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabPanel {
  // @include tabContent;
}
.tooltipTitle {
  @include tooltipTitle;
}

.tooltipBody {
  @include tooltipBody;
}

.required {
  @include required;
}

.paymentLogo {
  display: flex;
  margin: auto;

  span {
    svg {
      display: block;
    }

    &.gpayIcon {
      border: 1px solid #CCD0D4;
      background: #FAFAFB;
      border-radius: 8px;
      border: 0;
      padding: 0;
      background: none;
      border-radius: none;

      svg {
        width: 80px;
        height: 54px;
      }
    }
    &.applepayIcon {
      border: 1px solid #CCD0D4;
      background: #FAFAFB;
      border-radius: 8px;
      width: 55px;
      height: 31px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 42px;
        height: 18px;
      }
    }
    &.paypal {
      width: auto;
      margin-top: 1rem;
      border: none;
      background: none;
    }
    svg {
      width: 80px;
      height: auto;
    }
  }
}
