@import '../../../styles/_base.scss';

.summaryChunk {
  @include infoChunk;
}

.title {
  @include headingH3;
}

.addressList {
  @include plainList;
  padding: 0;
  margin: 0 0 -0.5rem 0;
}
