@import '../../../styles/_base.scss';

.summaryChunk {
  @include infoChunk(100%, 1, 30%, 0);
}

.title {
  @include headingH3;
  display: flex;
  justify-content: space-between;
}

.orderList {
  @include plainList;
  padding: 0;
  margin: 0 0 -0.5rem 0;
}

.orderLi {
  display: flex;
  gap: 1rem;
  strong {
    font-weight: normal;
    flex-basis: 0;
    flex-grow: 1;
  }
  span {
    text-align: right;
    flex-basis: 0;
    flex-grow: 1;
  }
}

.orderRemainingLi {
  border-top: solid 2px white;
  margin-top: 0.5rem;
  strong {
    padding-top: 1rem;
    font-weight: 700;
  }
  span {
    padding-top: 1rem;
    font-weight: 700;
  }
}

.orderCommission {
  strong {
    font-weight: 700;
  }
  font-weight: 700;
}

.mobileView {
  @include showHideLink;
  font-size: 3rem;
  line-height: 0;
}
