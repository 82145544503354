@import '../../styles/_base.scss';

.account {
  @include pageContainer;
  @media (max-width: 1110px) {
    padding: 1rem;
  }
}

.summaryChunks {
  @include infoChunks;
  margin-bottom: 2rem;
}

.success,
.error,
.delay {
  @include generalP;
  margin-bottom: 2rem;
  color: $black;
  border: solid 2px $black;
  border-radius: 4px;
  padding: 1rem 1rem 1rem 35px;
  font-weight: 700;
  position: relative;
}

.error {
  color: $error;
  border-color: $error;
  background-color: $errorFaded;
  @include cross(11px, 18px, auto, auto);
  // above explanation $top: 15px, $left: auto, $right: 0, $bottom: auto
}

.success {
  color: $valid;
  border-color: $valid;
  background-color: $validFaded;
  @include tick(10px, 20px, auto, auto, 19px, 12px, auto, auto);
  // above explanation $beforeTop: 15px, $beforeLeft: auto, $beforeRight: 0, $beforeBottom: auto, $afterTop: 23px, $afterLeft: auto, $afterRight: 7px, $afterBottom: auto
}

//Booking delay message
.delay {
  color: $warning;
  background-color: $warningFaded;
  border-radius: 8px;
  border: 1px solid $warningBorder;
  z-index: -1;

  @include info();

  p {
    margin-bottom: 0;
    position: relative;
    padding-left: 1rem;
  }
}

.loading {
  height: 60px;
}

.tabList {
  @include tabList;
  width: calc(100vw - 2rem);
  @include fromTablet {
    width: calc(100vw - 3rem);
  }
  @include fromDesktop {
    width: 100%;
  }
}

.tab {
  @include tab;
}

.tabPanel {
  // @include tabContent;
}

.quote {
  @include tabContent;
}
