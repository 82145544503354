@import '../../../styles/_base.scss';

.title {
  @include headingH2Section;
  flex-basis: 100%;
  margin: 0;
}
.payment {
  @include tabContent;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
  @include fromDesktop {
    flex-direction: row;
    gap: 2rem;
  }
}
