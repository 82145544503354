@import '../../../styles/_base.scss';

.accountsTitle {
  @include headingH1;

  width: 100%;
  margin-bottom: 0.5rem;
}
.accountsStatus {
  @include headingH2Status;

  width: 100%;
}

.accountsName {
  @include headingH2Status;
  font-weight: 400;
  width: 100%;
  margin-bottom: 0.5rem;
}

.accountsIntro {
  @include generalP;

  width: 100%;
  margin: 0 0 2rem 0;
}

.tooltipTitle {
  @include tooltipTitle;
}

.tooltipBody {
  @include tooltipBody;
}

.passengerAndDate {
  @include headingH3;
  font-weight: 500;
  strong {
    font-weight: 700;
  }
}
