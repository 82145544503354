@import '../../../styles/_base.scss';

.title {
  @include headingH2Section;
  margin-bottom: 3rem;
}

.ticketTitle {
  @include headingH3;
  font-size: 1.8rem;
}

.quote {
  @include tabContent;
}

.btn {
  @include downloadBtn;
  margin-top: 10px;
}

.subtitle {
  @include generalP;
  font-weight: 600;
}

.label {
  @include generalLabel;
  font-weight: 500;
}

.required {
  @include required;
}

.input {
  @include generalInput;
  width: 100%;
}

.error {
  border-color: red;
}

.errorMessage {
  color: red;
  font-size: 1.5rem;
  margin-top: 1px;
}

.attributeGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;
  width: 100%;

  .input {
    margin-top: 5px;
  }
}

.personGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  width: 100%;

  .attributeGroup {
    width: 100%;
  }
}

.attributeSection {
  width: 70%;
}
