@import '../../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.subtitle {
  @include generalP;
  margin-bottom: 2rem;
}

.transportTickets {
  width: 100%;

  @include fromDesktop {
    display: flex;
    gap: 2rem;
    flex-wrap: nowrap;
  }
}

.transportTicket {
  @include transportTickets;
}

.inboundOutboundHeading {
  @include headingH3;
  background: $dark-blue;
  color: $white;
  padding: 1rem;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin: 0;
  @include fromDesktop {
    padding: 1rem 3rem;
  }
}

.ticketContentJourney {
  padding: 1rem;
  width: 100%;
  background-color: $white;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 2rem);
    bottom: 0;
    left: 1rem;
    height: 1px;
    background-color: $grey;
  }

  @include fromDesktop {
    padding: 2rem 3rem;

    &:after {
      width: calc(100% - 6rem);
      left: 3rem;
    }
  }
}

.destination {
  @include headingH2;
}

.details {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.departDate {
  @include generalP;
  margin: 0;
  flex-grow: 1;
}

.timesChanges {
  flex-grow: 0;
  em,
  span {
    font-style: normal;
  }
}

.times {
  @include generalP;
  margin: 0;
}

.durationChanges {
  @include generalP;
  margin: 0;
}

.ticketContentPeople {
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;

  @include fromDesktop {
    padding: 2rem 3rem;
  }

  @include transportTicketsInside;
}

.imageHolder {
  flex-basis: 33%;
  flex-grow: 0;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
}

.peopleHolder {
  flex-grow: 1;
}

.people {
  @include generalP;
  margin: 0 0 0.5rem 0;
}

.confirmation {
  @include generalP;
}

.bookSection {
  text-align: right;
}

.book {
  @include generalP;
}

.externalLink {
  // external link styling
  @include externalLink;
}

.bookingLink {
  // external link styling
  @include externalLinkBtn;
}
