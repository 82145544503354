@import '../../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.rooms {
  @include accommodation;
}

.image {
  @include accommodationImage;
}

.info {
  @include accommodationInfo;
}

.roomName {
  @include headingH2;
}

.ticketSubtitle {
  @include generalP;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  @include fromDesktop {
    margin-top: 2rem;
    flex-direction: row;
    gap: 2rem;
  }
}

.detailsChunk {
  @include backgroundChunk;
  flex-basis: 100%;
  flex-grow: 1;
  @include fromDesktop {
    flex-basis: 40%;
  }
}

// Lists

.occupants {
  list-style-type: none;
  margin: 0 0 -0.5rem 0;
  padding: 0;
}

.occupantsItem {
  @include generalP;
  margin-bottom: 0.5rem;
  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
}

.extras {
  list-style-type: none;
  margin: -0.5rem 0 0 0;
  padding: 0;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.extrasItem {
  @include generalP;
  flex-basis: 100%;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
  background-color: $grey-light;
  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
}

.extrasItemChild {
  @include generalP;
  flex-basis: 100%;
  margin: -1px 0 0 0;
  padding: 0 0 0.5rem 0;
  background-color: $grey-light;
  em {
    font-style: normal;
  }
  strong {
    font-weight: normal;
  }
}
