@import '../../../../styles/_base.scss';

.giropay {
  @include tabContent;
}
.info {
  @include generalP;
}
.submit {
  @include linkBtn;
  width: auto;
}
.giropayFrame {
  width: 100%;
  iframe {
    width: 100%;
    min-height: 0;
    border: 0;
    padding: 0;
    margin: 0;
  }
}
