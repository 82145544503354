@import '../../../styles/_base.scss';

.ticketItem {
  @include ticketsListItem;

  .image {
    flex-basis: 20%;
    flex-grow: 0;
    img {
      width: 100%;
      max-width: 100%;
      float: left;
      clear: both;
      @include clearFix;
    }
  }
  .info {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 0 1rem 1rem;
    @include fromDesktop {
      padding: 2rem;
    }
  }
}

.hotelTicketItem {
  @include accommodation;

  .image {
    @include accommodationImage;
  }

  .info {
    @include accommodationInfo;
  }
}

.status {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
  @include fromDesktop {
    flex-basis: 25%;
    flex-grow: 0;
    padding: 2rem 2rem 2rem 0;
  }
}
.title {
  @include headingH2;
}
.price {
  @include generalP;
}
.subtitle {
  @include generalP;
  font-weight: bold;
}

.bookingStatus {
  @include generalP;
  font-weight: bold;
  font-size: 150%;
  font-style: italic;
}

.number {
  @include generalP;
  span {
    display: block;
  }
}
.dates {
  @include generalP;
}
.guide {
  @include generalP;
}
.statusTitle {
  @include status;
}
.statusDownload {
  @include generalP;
}
.statusSpan {
  display: inline;
}
.statusButton {
  @include downloadBtn;
}
.dsdStatusButton {
  @include dsdDownloadBtn;
}
.missingAttribute {
  color: red;
}

.tooltipTitle {
  @include tooltipTitle;
}

.tooltipBody {
  @include tooltipBody;
}
