// media query mixins
$mobile: 360px;
$tablet: 768px;
$desktop: 1024px;
$desktopWider: 1078px;

@mixin fromMobile {
  @media only screen and (min-width: $mobile) {
    @content;
  }
}

@mixin untilTablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin fromDesktopWider {
  // This is because of a modal issue - shouldn't be 95% and 1024px until this width, not 1024
  @media only screen and (min-width: $desktopWider) {
    @content;
  }
}

// colors
$grey: #e4e4e4;
$grey-disabled: #f4f4f4;
$grey-light: #f0f0f0;
$green: #00c853;
$pink: #e43f8b;
$blue: #1a9ee2;
$white: #fff;
$black: #00273b;
$dark-blue: #00496c;
$valid-info: $dark-blue;
$red: #f04326;
$yellow: #fdbc2e;
$light-yellow: #feeebf;
$dark-yellow: #bb8838;
$link: $blue;
$link-hover: #64b5f6;
$error: $red;
$errorFaded: #ffe2e2;
$valid: $green;
$validFaded: #e7fbed;
$warning: $dark-yellow;
$warningFaded: $light-yellow;
$warningBorder: $yellow;
$price: $green;
$price-button: $price;
$price-border: $price;
$default-price: #8ec9f9;
$lowest-price: #e3f2fe;
$tooltip-blue: #0a6ab9;
$tooltip-black: #161616;
$required: $red;
$tick: $green;
$listdot: $blue;
$right-col-width-original: 25%;
$right-col-width-tablet-original: 25%;
$left-col-width-original: calc(75% - 2rem);
$form-icon-gutter: 20px;
$font-size-tiny: 1.1rem;
$font-size-smallest: 1.2rem;
$font-size-smaller: 1.4rem;
$font-size-small: 1.5rem;
$font-size-medium: 1.6rem;
$font-size-big: 2rem;
$font-size-big-price: 3.2rem;
$font-size-biggish: 2.3rem;
$font-size-bigger: 2.5rem;
$font-size-biggest: 4rem;

// Make sure all sites can read rems the same
html {
  font-size: 62.5%;
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
}

// mixins

@mixin clearFix {
  :after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

@mixin visuallyHidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

// .visuallyHidden {
//   @include visuallyHidden;
// }

:global(.visuallyHidden) {
  @include visuallyHidden;
}

@mixin hideOnMobile {
  @include untilTablet {
    @include visuallyHidden;
  }
}

@mixin cross($top: 15px, $left: auto, $right: 0, $bottom: auto) {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 20px;
    width: 5px;
    border-radius: 2px;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
    position: absolute;
    background: $error;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@mixin tick(
  $beforeTop: 15px,
  $beforeLeft: auto,
  $beforeRight: 0,
  $beforeBottom: auto,
  $afterTop: 23px,
  $afterLeft: auto,
  $afterRight: 7px,
  $afterBottom: auto
) {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 20px;
    width: 5px;
    border-radius: 2px;
    top: $beforeTop;
    left: $beforeLeft;
    right: $beforeRight;
    bottom: $beforeBottom;
    position: absolute;
    background: $valid;
    transform: rotate(45deg);
  }

  &::after {
    height: 10px;
    top: $afterTop;
    left: $afterLeft;
    right: $afterRight;
    bottom: $afterBottom;
    transform: rotate(-45deg);
  }
}

@mixin info() {
  ::before {
    content: '';
    width: 20px;
    height: 20px;
    background: url(./../svgs/info.png) no-repeat;
    background-size: 20px;
    background-position: center;
    margin-left: -3rem;
    float: left;

    @include untilDesktop {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
    }
  }
}

@mixin pageContainer {
  width: 100%;
  margin: 1rem 0;
  @include fromDesktop {
    margin: 2rem 0;
  }
}

@mixin headingH1 {
  color: $black;
  font-size: $font-size-biggest;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

@mixin headingH2Section {
  color: $black;
  font-size: $font-size-big;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

@mixin headingH2 {
  color: $black;
  font-size: $font-size-biggish;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

@mixin headingH2Status {
  color: $black;
  font-size: $font-size-bigger;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

@mixin headingH3 {
  color: $black;
  font-size: $font-size-big;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

@mixin generalP {
  color: $black;
  font-size: $font-size-medium;
  line-height: 1.4;
  padding: 0;
  margin: 0 0 1rem 0;
  font-weight: 400;
}

@mixin fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

@mixin field {
  width: 100%;
  margin: 0 0 1.5rem 0;
  padding: 0;
}

@mixin generalError {
  @include generalP;
  margin: 0;
  padding: 0;
  color: $error;
}
@mixin generalLabel {
  color: $black;
  font-size: $font-size-small;
  line-height: 1.4;
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-weight: 700;
  display: block;
}

@mixin generalInput {
  color: $black;
  font-size: $font-size-medium;
  line-height: 1.4;
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
  border: solid 1px #00273b;
  background-color: #fff;
  padding: 1.5rem 1rem;
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: 100%;
  &:hover,
  &:focus {
    outline: none;
    border-color: $link-hover;
  }
}

@mixin generalSelect {
  color: $black;
  font-size: $font-size-medium;
  line-height: 1.4;
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
  border: solid 1px #00273b;
  background-color: #fff;
  padding: 1.5rem 1rem;
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: 100%;
  &:hover,
  &:focus {
    outline: none;
    border-color: $link-hover;
  }
}

@mixin linkColor {
  text-decoration: none;
  color: $link;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: $link-hover;
  }
}

@mixin plainList {
  padding: 0 0 1rem 0;
  margin: 0;
  list-style-type: none;
  li {
    color: $black;
    font-size: $font-size-small;
    line-height: 1.4;
    list-style-type: none;
    padding: 0;
    margin: 0 0 0.5rem 0;
  }
}

@mixin generalList {
  @include plainList();
  li {
    padding: 0 0 0 20px;
    background-repeat: no-repeat;
    background-position: left 4px;
    background-size: 14px;
    background-image: url(./../svgs/star.svg);
  }
}

@mixin removeButtonStyles {
  border: 0;
  background: transparent;
  outline: 0;
  padding: 0;
  margin: 0;
}

@mixin showHideLink {
  @include removeButtonStyles;

  display: inline-block;
  cursor: pointer;
  font-size: $font-size-small;
  line-height: 1.4;
  text-decoration: none;
  color: $link;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: $link-hover;
  }
}

@mixin tabList {
  margin: 0 0 -1px 0;
  padding: 0;
  list-style-type: none;
  white-space: nowrap;
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

@mixin tab {
  width: auto;
  display: inline-block;
  border: 1px solid $grey;
  border-bottom-color: $grey;
  border-radius: 4px 4px 0 0;
  background-color: $grey-light;
  margin: 0;
  color: $link;
  font-size: $font-size-smaller;
  font-weight: bold;
  line-height: 1.4;
  cursor: pointer;
  outline: none;
  padding: 1.5rem 1rem;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 3px;
    fill: $link;
  }

  @include fromDesktop {
    font-size: $font-size-small;
    padding: 1.5rem 2rem;
    font-size: $font-size-medium;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &:hover {
    color: $black;
    background-color: $white;

    svg {
      fill: $black;
    }
  }
}

:global(.react-tabs__tab--selected) {
  border-bottom-color: $white;
  color: $black;
  background-color: $white;
  cursor: auto;

  svg {
    fill: $black;
  }
}

@mixin tabContent {
  width: 100%;
  border: 1px solid $grey;
  background-color: $white;
  padding: 2rem 1rem;
  @include fromDesktop {
    padding: 2rem;
  }
}

// loading spinner
@mixin loading() {
  position: relative;
  pointer-events: none;

  // overlay
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 3;
    opacity: 0.7;
    border-radius: 4px;
  }

  // spinner
  &::after {
    background: none;
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
    margin-left: -10px !important;
    border-radius: 50% !important;
    display: inline-block !important;
    border: 4px solid $green;
    border-top-color: transparent;
    z-index: 4;
    animation: spinner 0.6s linear infinite;
  }

  &:disabled {
    &::before {
      background: transparent;
      border-color: transparent;
    }
    &::after {
      background: transparent;
      border-color: transparent;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@mixin externalIcon($initialColor: $link, $hoverColor: $link-hover) {
  &::after {
    content: '';
    background-color: $initialColor;
    height: 16px;
    width: 20px;
    display: inline-block;
    -webkit-mask-image: url(./../svgs/new-window.png);
    mask-image: url(./../svgs/new-window.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center right;
    mask-position: center center right;
    position: relative;
    top: 6px;
  }

  &:hover {
    &::after {
      background-color: $hoverColor;
    }
  }
}

@mixin btnTemplate {
  font-size: $font-size-medium;
  font-weight: 700;
  padding: 1.5rem 2rem;
  line-height: 1.2;
  text-align: center;
  text-transform: capitalize;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  position: relative;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
  }

  &:disabled,
  &:global(.disabled) {
    background-color: $grey;
    cursor: not-allowed;
    color: $white;

    &:hover,
    &:focus {
      background: $grey;
      color: $white;
    }
  }
}

@mixin btn {
  @include btnTemplate;
  color: $white;
  background: $green;

  // &::after {
  //   border-color: $white;
  //   border-style: solid;
  //   border-width: 3px 3px 0 0;
  //   content: "";
  //   display: inline-block;
  //   height: 4px;
  //   position: relative;
  //   top: 7px;
  //   left: 0;
  //   transform: rotate(45deg);
  //   vertical-align: top;
  //   width: 4px;
  //   margin-left: 0.5rem;
  // }

  &:hover,
  &:focus {
    background: darken($green, 10%);
    color: $white;
  }

  &:global(.loading) {
    @include loading();
  }
}

@mixin linkBtn {
  @include btnTemplate;
  color: $white;
  background: $link;

  &:hover,
  &:focus {
    background: $link-hover;
    color: $white;
  }
}

@mixin externalLinkBtn {
  @include linkBtn;
  @include externalIcon($white, $white);
}

@mixin downloadBtn {
  @include btnTemplate;
  color: $white !important;
  background: $green;

  &:hover,
  &:focus {
    background: darken($green, 10%);
    color: $white;
  }

  &:global(.loading) {
    @include loading();
  }
}

@mixin dsdDownloadBtn {
  @include btnTemplate;
  color: $white !important;
  background: $pink;

  &:hover,
  &:focus {
    background: darken($pink, 10%);
    color: $white;
  }

  &:global(.loading) {
    @include loading();
  }
}

@mixin externalLink {
  @include externalIcon();
}

@mixin status {
  @include generalP;
}

@mixin infoChunks {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
  @include fromDesktop {
    flex-direction: row;
    gap: 2rem;
  }
}

@mixin backgroundChunk {
  background-color: $grey-light;
  padding: 1rem;
  @include fromDesktop {
    padding: 2rem;
  }
}

@mixin infoChunk(
  $flexBasisMob: 100%,
  $flexGrowMob: 1,
  $flexBasisDesk: 0,
  $flexGrowDesk: 1
) {
  @include backgroundChunk;
  flex-basis: $flexBasisMob;
  flex-grow: $flexGrowMob;
  @include fromDesktop {
    flex-basis: $flexBasisDesk;
    flex-grow: $flexGrowDesk;
  }
}

@mixin dropShadow {
  border-top: 1px solid $grey;
  background-color: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

@mixin accommodation {
  width: 100%;
  border: none;
  padding: 0;
  border-radius: 16px;
  margin: 0 0 2rem 0;
  position: relative;
  @include dropShadow;
  @include fromDesktop {
    display: flex;
    &:before,
    &:after {
      content: '';
      width: 25px;
      height: 50px;
      background-color: $white;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      left: -5px;
      border-radius: 0 25px 25px 0;
      border-left: solid 5px $white;
      box-shadow: inset 0px 8px 4px -4px rgb(0 0 0 / 25%);
    }
    &:after {
      right: -6px;
      border-radius: 25px 0 0 25px;
      border-right: solid 5px $white;
      box-shadow: inset 4px 8px 4px -4px rgba(0, 0, 0, 0.25);
    }
  }
}

@mixin accommodationImage {
  background-color: $white;
  border-radius: 16px 16px 0 0;
  position: relative;
  overflow: hidden;
  aspect-ratio: 4 / 2;
  @include fromDesktop {
    flex-basis: 20%;
    border-radius: 16px 0 0 16px;
    aspect-ratio: unset;
    min-height: 240px;
    &:before {
      content: '';
      display: block;
      height: 250%;
      width: 200%;
      position: absolute;
      border-radius: 50%;
      -webkit-box-shadow: 0 0 0 100px $white;
      box-shadow: 0 0 0 100px $white;
      -webkit-transform: translate(-50.5%, -30.5%);
      transform: translate(-50.5%, -30.5%);
    }
  }
  img {
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    @include fromDesktop {
      height: 100%;
    }
  }
}

@mixin accommodationInfo {
  padding: 1.5rem 1rem 2rem 1rem;
  @include fromDesktop {
    flex-basis: 80%;
    flex-grow: 1;
    padding: 2rem calc(2rem + 25px) 2rem 2rem;
  }
}

@mixin transportTickets {
  width: 100%;
  border: none;
  padding: 0;
  border-radius: 16px;
  margin: 0 0 2rem 0;
  @include dropShadow;
}

@mixin transportTicketsInside {
  @include fromDesktop {
    position: relative;
    &:before,
    &:after {
      content: '';
      width: 20px;
      height: 40px;
      background-color: $white;
      position: absolute;
      z-index: 1;
      top: 0;
      transform: translateY(-50%);
    }
    &:before {
      left: -5px;
      border-radius: 0 25px 25px 0;
      border-left: solid 5px $white;
      box-shadow: inset 0px 8px 4px -4px rgb(0 0 0 / 25%);
    }
    &:after {
      right: -6px;
      border-radius: 25px 0 0 25px;
      border-right: solid 5px $white;
      box-shadow: inset 4px 8px 4px -4px rgba(0, 0, 0, 0.25);
    }
  }
}

@mixin ticketsList {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include fromDesktop {
    gap: 2rem;
  }
}

@mixin ticketsListItem {
  flex-basis: 100%;
  background-color: $white;
  border: 1px solid $grey;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  @include fromDesktop {
    padding: 0;
  }
}

@mixin tooltipTitle {
  @include generalP;
  color: $tooltip-blue;
  border: 2px solid $tooltip-blue;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 1.1;
  font-weight: 600;
  font-size: 1.3rem;
  margin: 0;
  vertical-align: text-bottom;
}

@mixin tooltipBody {
  * {
    @include generalP;
    max-width: 250px;
    position: relative;
    background-color: $tooltip-black;
    line-height: 1.5;
    font-size: 1.3rem;
    opacity: 0.9;
    z-index: 1;
    color: white !important;
  }
}

@mixin required {
  color: $required;
}
