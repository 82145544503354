@import '../../../styles/_base.scss';

.title {
  @include headingH2Section;
}

.ticketsToPost {
  @include tabContent;
}

.ticketsList {
  @include ticketsList;
}
